@import 'src/utils/utils';

.header {
  @include zero;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 10px;
  margin-right: 16px;
  margin-left: 16px;
  gap: 15px;

  @media(min-width: $lg) {
    display: flex;
    flex-direction: row;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 15px;
    margin-left: 0;
  }
    @media(min-width: 1921px) {
      position: relative;
  }

  &__links {
    @include zero;
    display: none;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 10px;

    @media(min-width: $lg) {
      // display: flex;
      display: inline;
    }
    @media(min-width: 1921px) {
      width: 1000px;
      min-height: 44px;
      margin-left: auto;
      margin-right: auto;
      overflow-x: hidden;
    }
  }

  // &__link-splitter {
  //   @include Text-14-reg;
  //   color: $Main-1;

  //   &--white {
  //     color: $Main-2;
  //   }
  // }

  &__link {
    @include Text-14-reg;
    @include zero;
    display: inline;
    // display: flex;
    // align-items: center;
    // column-gap: 5px;
    // padding-top: 5px;
    // padding-bottom: 5px;
    color: $Main-1;
    line-height: 2;
    text-decoration: none;
    transition: color 0.3s;

    // span {
    //   max-width: 360px;
    //   text-overflow: ellipsis;
    //   overflow: hidden;
    //   white-space: nowrap;
    // }

    svg {
      width: 18px;
      height: 18px;
      margin-bottom: -3px;
      stroke: currentcolor;
    }

    &:hover {
      @media(min-width: $lg) {
        color: $Corporate-2;
      }
    }

    &--white {
      color: $Main-2;
    }

    &--inactive {
      color: $t-60;
      pointer-events: none;
    }

  }

  &__button {
    @include zero;
    border: 0;
    width: 40px;
    height: 40px;
    display: none;
    align-items: center;
    justify-content: center;
    padding: 5px;
    padding-right: 6px;
    padding-left: 6px;
    border-radius: 10px;
    background-color: $Main-2;
    text-decoration: none;
    cursor: pointer;

    @media(min-width: $lg) {
      display: flex;
      width: 44px;
      min-width: 44px;
      height: 44px;
    } 
    @media(min-width: 1921px) {
      // position: absolute;
    }

    svg {
      width: 24px;
      height: 24px;
      stroke: $Main-1;
      transition: stroke 0.3s;
    }

    p {
      @include zero;
      @include Text-18-reg;
      display: block;
      width: 24px;
      height: 24px;
      color: $Main-2;
      background-color: $Corporate-2;
      border-radius: 50%;
      line-height: 1.438rem;
      transition: transform 0.3s;
      text-align: center;
      will-change: transform;
    }

    &:hover {
      @media(min-width: $lg) {

        svg {
          stroke: $Corporate-2;
        }

        p {
          transform: scale3d(1.1, 1.1, 1.1);
        }
      }
    }
  }

  &__back {
    @include zero;
    @include Text-14-reg;
    display: none;
    align-items: center;
    justify-content: center;
    padding-top: 4px;
    padding-right: 12px;
    padding-bottom: 5px;
    padding-left: 6px;
    color: $Main-1;
    border: 0;
    background-color: $Main-2;
    border-radius: 10px;
    text-decoration: none;
    transition: color 0.3s;
    column-gap: 5px;

    @media(min-width: $lg) {
      @include Text-16-reg;
      display: inline-flex;
      min-height: 44px;
      padding-top: 8px;
      padding-right: 20px;
      padding-bottom: 8px;
      padding-left: 10px;
    }

    &:hover {
      @media(min-width: $lg) {
        color: $Corporate-2;

        svg {
          transform: translate3d(-3px, 0, 0);
        }
      }
    }

    svg {
      width: 24px;
      height: 24px;
      stroke: currentcolor;
      transition: transform 0.3s;
      will-change: transform;
    }
  }

  &__search {
    @include zero;
    position: relative;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 15px;

    @media(min-width: $lg) {
      margin-top: unset;
      margin-bottom: unset;
    }
    @media(min-width: $xl) {
      max-width: 1000px;
    }
    @media(min-width: 1921px) {
      max-width: 1000px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__actions {
    display: flex;
    margin-left: auto;
    align-items: center;
    column-gap: 10px;

    @media(min-width: 1921px) {
      position: absolute;
      right: 0;
    } 
  }

  &--white {

    .header__links {
      color: $Main-2;
    }

    .header__link {
      color: $Main-2;

      &:hover {
        @media(min-width: $lg) {
          color: $Corporate-2;
        }
      }
    }
  }
}